<template>
  <v-app>
  <v-snackbar
        v-model="snackWithButtons"
        :timeout="timeout"
        top
        centered
      >
        {{ snackWithBtnText }}
        <v-spacer />
        <v-btn
          dark
          text
          color="#495386"
          @click.stop="refreshApp"
        >
          {{ snackBtnText }}
        </v-btn>
        <v-btn
          dark
          icon
          @click="snackWithButtons = false"
        >
          close
        </v-btn>
      </v-snackbar>

      <router-view/>
  </v-app>
</template>

<script>
// (function(w,d,v3){

//     w.chaportConfig = {
//     appId : '5fbfa1768c3bfe4d3323278f'
//     };
//     if(w.chaport)return;v3=w.chaport={};v3._q=[];v3._l={};v3.q=function(){v3._q.push(arguments)};v3.on=function(e,fn){if(!v3._l[e])v3._l[e]=[];v3._l[e].push(fn)};var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://app.chaport.com/javascripts/insert.js';var ss=d.getElementsByTagName('script')[0];ss.parentNode.insertBefore(s,ss)})
//     (window, document);

export default {
  name: 'App',
   watch: {
      '$route' (to) {
        document.title = to.meta.title || 'Cubixd'
      }
    },

  data: () => ({
    //
      refreshing: false,
      registration: null,
      snackBtnText: '',
      snackWithBtnText: '',
      snackWithButtons: false,
      timeout: -1,

  }),
    methods: {
    showRefreshUI (e) {
      this.registration = e.detail;
      this.snackBtnText = 'Refresh';
      this.snackWithBtnText = 'New version available!';
      this.snackWithButtons = true;
    },
    refreshApp () {
        // this.snackWithButtons = false;
        // Protect against missing registration.waiting.
        if (!this.registration || !this.registration.waiting) { 
            this.snackBtnText = "ERROR"
          return; 
          }else{
                    this.registration.waiting.postMessage('skipWaiting');
                     this.snackWithButtons = false;

          }
      },
  },
  created: function (){
        // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });

  }

};
</script>