import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'
import jwt from 'jsonwebtoken'

  var axios = Axios.create({
	  baseURL: process.env.VUE_APP_API_URL
  })

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    authStatus: '',
    token: localStorage.getItem('cubixd_token') || '',
    isMobile: false,
    deviceSize: 0,
    account_type: "",
  },
  getters: {
    authorized: state => !!state.token,
    authstatus: state => state.authStatus,
    isMobile: state => state.deviceSize < 768
  },
  mutations: {
    auth_request (state) {
     state.authStatus = 'loading'
    },
    setAccountType (state) {
      state.account_type = 'loading'
     },
    auth_success (state, { token}) {
      state.authStatus = 'success'
      state.token = token
    },
    auth_error (state) {
      state.authStatus = 'error'
    },
    resize (state, deviceSize){
      state.deviceSize = deviceSize;
    },
    logout (state) {
      state.authStatus = ''
      state.token = ''
    }
  },
  actions: {
    login ({ commit }, userData) {
        return new Promise((resolve, reject) => {
          console.log("ENV VAR: " + process.env.VUE_APP_API_URL)
          commit('auth_request')
          axios.post('login', { email: userData.email, password: userData.password })
            .then(response => {
              const token = response.data.access_token
              const refreshToken = response.data.refresh_token;
              const decoded = jwt.decode(token);
              const name = decoded.name
              console.log(decoded)
              this.state.account_type = decoded.account_type
              // storing jwt in localStorage. https cookie is safer place to store
              localStorage.setItem('name', name)
              localStorage.setItem('cubixd_token', token)
              localStorage.setItem('cubixd_refreshToken', refreshToken)
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
              // mutation to change state properties to the values passed along
              commit('auth_success', {token})
              resolve(response)
            })
            .catch(err => {
              console.log(err)

              commit('auth_error')
              localStorage.removeItem('token')
              reject(err)
            })
        })
  },
  register ({ commit }, userData) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('register', { userData })
          .then(response => {

            resolve(response)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
},
  paypalCheckout ({ commit }, userData) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios.post('/paypal/register', { userData })
          .then(response => {

            resolve(response)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      })
},
stripePayment ({ commit }, userData) {
  return new Promise((resolve, reject) => {
    commit('auth_request')
    console.log(userData)
    axios.post('/payment/stripe', { userData })
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
createSite ({ commit }, userData) {
  return new Promise((resolve, reject) => {
    commit('sending')
    axios.post('/sites', { userData }, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') } })
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
goLive ({ commit }, userData) {
  return new Promise((resolve, reject) => {
    commit('sending')
    axios.post('/sites/live?domain='+userData.domain, {userData},{headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') } })
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
userDetails ({ commit }) {
  return new Promise((resolve, reject) => {
    commit('auth_request')
    axios.get('/user/details' , {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
userData ({ commit }) {
  return new Promise((resolve, reject) => {
    commit('auth_request')
    axios.get('/user' , {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
updatePassword ({ commit },userData) {
  return new Promise((resolve, reject) => {
    commit('auth_request')
    axios.post('/user/password',{userData} , {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
forgotPassword ({ commit },userData) {
  return new Promise((resolve, reject) => {
    commit('auth_request')
    axios.post('/user/forgotPassword?email='+userData.email)
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
resetPassword ({ commit },userData) {
  return new Promise((resolve, reject) => {
    commit('auth_request')
    axios.post('/user/password/reset?token='+userData.token, {userData})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
getPhpVersions () {
  return new Promise((resolve, reject) => {
    axios.get('/sites/php', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
getAllVisits ({commit} , userData) {
  commit('getting')
  return new Promise((resolve, reject) => {
    axios.get('/sites/visits?start_date='+userData.start_date+'&end_date='+userData.end_date, {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
getSites () {
  return new Promise((resolve, reject) => {
    axios.get('/sites', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
getSiteDetails ({ commit }, userData) {
  return new Promise((resolve, reject) => {
    commit('getting')
    axios.get('/sites/data?domain='+userData.domain , {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
getRegions () {
  return new Promise((resolve, reject) => {
    axios.get('/sites/regions', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
getIpGeos () {
  return new Promise((resolve, reject) => {
    axios.get('/pbn/ip/geo', {headers: {'Authorization' : 'Bearer ' + localStorage.getItem('cubixd_token') }})
      .then(response => {
        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},

validateVatNo ({ commit },userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.get('/vat/validate?countryCode='+userData.countryCode+"&vatNumber="+userData.vatNumber)
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},

getSalesTax ({ commit },userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.get('/vat/rate?countryCode='+userData.countryCode+"&vatNumber="+userData.vatNumber)
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
getCustomerPortal ({ commit }) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.get('/billing/portal' , {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_token')}})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
getJobStatus ({ commit },userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.get('/job/'+userData.job_uuid , {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_token')}})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
updateJobStatus ({ commit },userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.patch('/job/'+userData.job_uuid ,{}, {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_token')}})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
createStaging ({ commit }, userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.post('/sites/staging?domain='+userData.domain, {backupId: userData.backupId}, {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_token')}})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
createBackup ({ commit }, userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.post('/sites/backup/create?domain='+userData.domain, {}, {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_token')}})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
restoreBackup ({ commit }, userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.post('/sites/restore?domain='+userData.domain+'&backupId='+userData.backupId, {} , {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_token')}})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
deleteWebsite ({ commit }, userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.delete('/sites?domain='+userData.domain , {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_token')}})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
createRedirect ({ commit }, userData) {
  commit("getting")
  return new Promise((resolve, reject) => {
    axios.post('/sites/redirect?domain='+userData.domain, {to: userData.to, from: userData.from, https: userData.https } , {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_token')}})
      .then(response => {

        resolve(response)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
},
  logout ({ commit }) {
    return new Promise((resolve) => {
      commit('logout')
      localStorage.removeItem('cubixd_token')
      localStorage.removeItem('name')
      localStorage.removeItem('cubixd_refreshToken')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
  refreshToken ({ commit }) {
    return axios.get('/refresh_token', {headers: {'Authorization': "bearer " + localStorage.getItem('cubixd_refreshToken')}})
      .then(response => {
        const token = response.data.access_token
        const decoded = jwt.decode(token);
        const name = decoded.name
        this.state.account_type = decoded.account_type
        console.log(token)
        localStorage.setItem('cubixd_token', token)
        localStorage.setItem('name', name)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
      })
      .catch(() => {
        commit('logout')
        localStorage.removeItem('token')
      })
  },


  }
})
