/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import store from '../store'

export default [
//   {
//     path: '*',
//     meta: {
//       name: '',
//       requiresAuth: true
//     },
//     redirect: {
//       path: '/dashboard'
//     }
//   },
  // This  allows you to have pages apart of the app but no rendered inside the dash
//   {
//     path: '/',
//     meta: {
//       name: 'Dashboard',
//       requiresAuth: true
//     },
//     component: () => import(`@/views/Dashboard.vue`),
//     // redirect if already signed in
//     beforeEnter: (to, from, next) => {
//       if (store.getters.authorized) {
//         next()
//       } else {
//         next('/login')
//       }
//     }
//   },
  {
    path: '/login',
    meta: {
      title: "Cubixd | Login",
      name: 'login',
      requiresAuth: false
    },
    component: () => import(`@/views/Login.vue`),
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        next('/')
      } else {
        next()
      }
    }
  },
  {
    path: '/resetPassword/',
    meta: {
      name: 'Reset Password',
      title: 'Cubixd | Reset Password',
      requiresAuth: false

    },
    component: () => import(`@/views/ResetPassword.vue`),
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        next('/')
      } else {
        next()
      }
    }
  },
  {
    path: '/logout',
    meta: {
      name: 'logout',
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
        store.dispatch('logout')
        next('/login')
    }
  },
  {
    path: '/register',
    meta: {
      title: "Cubixd | Register",
      name: 'register',
      requiresAuth: false
    },
    component: () => import(`@/views/Register.vue`),
    // redirect if already signed in
    beforeEnter: (to, from, next) => {
      if (store.getters.authorized) {
        next('/')
      } else {
        next()
      }
    }
  },
  // add any extra routes that you want rendered in the dashboard as a child below. Change toolbar names here
  {
    path: '/',
    meta: {
      title: "Cubixd | Dashboard",
      name: 'Dashboard',
      requiresAuth: true
    },
    component: () => import(`@/views/Dashboard/Template.vue`),
    children: [
      {
        path: '/',
        meta: {
          title: "Cubixd | Dashboard",
          name: 'Dashboard',
          requiresAuth: true
        },
        component: () => import(`@/views/Dashboard/Dashboard.vue`)
      },
      {
        path: '/sites',
        meta: {
          name: 'Sites',
          title: 'Cubixd | Sites',
          requiresAuth: true,

        },
        component: () => import(`@/views/Dashboard/Sites.vue`)
      },
      {
        path: '/sites/new',
        meta: {
          name: 'New Site',
          title: 'Cubixd | New Site',
          requiresAuth: true,

        },
        component: () => import(`@/views/Dashboard/NewSite.vue`)
      },
         {
        path: '/sites/:domain',
        meta: {
          name: 'Site',
          title: 'Cubixd | Site',
          requiresAuth: true,

        },
        component: () => import(`@/views/Dashboard/Site.vue`)
      },
      {
        path: '/job/:job_uuid',
        meta: {
          name: 'Job',
          title: 'Cubixd | JOB',
          requiresAuth: true,

        },
        component: () => import(`@/views/Dashboard/Job.vue`)
      },
      {
        path: '/settings/',
        meta: {
          name: 'Settings',
          title: 'Cubixd | Settings',
          requiresAuth: true,

        },
        component: () => import(`@/views/Dashboard/Settings.vue`)
      },
   
      {
        path: '/sites/:domain/backups',
        meta: {
          name: 'Backups',
          title: 'Cubixd | Backups',
          requiresAuth: true,

        },
        component: () => import(`@/views/Dashboard/Backups.vue`)
      },
    ]
  }
]
