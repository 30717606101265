/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// Routes
import paths from './paths'
import jwt from 'jsonwebtoken'

Vue.use(Router)

// Create a new router
const router = new Router({
  base: '/',
  mode: 'history',
  routes: paths,

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

// Route guard checks to see if you are logged in, if not reroutes to login
// to is where you are going, matched.some is to find which routes have requiresAuth
router.beforeEach(async (to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.authorized) {
        const token =localStorage.getItem('cubixd_token')
        const { exp } = jwt.decode(token);
        if(Date.now() >= exp*1000){
          const refreshToken = localStorage.getItem('cubixd_refreshToken')
          const { exp } = jwt.decode(refreshToken);
          if(Date.now() >= exp*1000){
            //Log out and re authneticate
            await store.dispatch('logout')


          }else {
            await store.dispatch('refreshToken')
          }
        }
        next()
        return
      }
      next('/login')

  } else {
    next()
  }
})


export default router
